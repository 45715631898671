
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { TypeData } from '@/types/projectFile'

@Component
export default class FileType extends Vue {
  @Prop({ default: false }) private isShowType!: boolean
  private addLine: number | null = null
  private tableData: TypeData[]=[]
  private loading = false
  private info: TypeData = this.initAddForm

  @Watch('isShowType')
  onShowChange (value: boolean) {
    value && this.getTypeList()
  }

  get initAddForm () {
    return {
      typesName: '',
      typeId: ''
    }
  }

  getTypeList () {
    this.loading = true
    this.$axios.get(this.$apis.projectFile.selectProjectFileTypesByList).then(res => {
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  addUpdate (index: number, row: TypeData) {
    if (this.addLine === index) {
      if (this.info.typesName) {
        const url = this.info.typeId ? this.$apis.projectFile.updateProjectFileTypes : this.$apis.projectFile.insertProjectFileTypes
        this.$axios.post(url, this.info).then(() => {
          this.$message.success(this.info.typeId ? '编辑文件类型成功' : '添加文件类型成功')
          this.addLine = null
          this.info = JSON.parse(JSON.stringify(this.initAddForm))
          this.getTypeList()
        })
      } else {
        this.$message.warning('请输入文件类型名称')
      }
    } else if (this.addLine !== null) {
      this.$message.warning('请先保存')
    } else {
      this.addLine = index
      this.info = { ...row }
    }
  }

  addFormNew () {
    if (!this.addLine) {
      this.info = JSON.parse(JSON.stringify(this.initAddForm))
      this.tableData.push(this.info)
      this.addLine = this.tableData.length - 1
      // 滚动到表格最底部
      this.$nextTick(() => {
        (this.$refs.addTable as any).bodyWrapper.scrollTop = (this.$refs.addTable as any).bodyWrapper.scrollHeight
      })
    } else {
      this.$message.warning('请先保存')
    }
  }

  onDelete (row: TypeData) {
    this.$confirm(`是否删除文件类型【${row.typesName}】?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.projectFile.deleteProjectFileTypes,
        {
          typeId: row.typeId
        }).then(() => {
        this.$message.success('删除成功')
        this.addLine = null
        this.getTypeList()
      })
    })
  }

  close () {
    // 数据清除
    this.addLine = null
    this.$emit('update:isShowType', false)
  }
}
