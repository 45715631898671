
import { Component, Vue } from 'vue-property-decorator'
import { TableData, Info } from '@/types/projectFile'
import FileType from './FileType.vue'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/common'
import { ElForm } from 'element-ui/types/form'

@Component({
  components: { FileType, UploadFile }
})
export default class List extends Vue {
  private searchInfo = {
    projectName: '',
    cateName: ''
  }

  private tableData: TableData[] =[]

  private page = 1
  private total = 0
  private size = 10
  private loading = false
  private typeList = []
  private isShowType = false

  private addShow = false
  private submitShow = false
  private info: Info={
    fileTypeId: '',
    projectId: '',
    comments: '',
    resourceList: []
  }

  private rules = {
    fileTypeId: [{ required: true, message: '请选择文件类型', trigger: 'change' }],
    projectId: [{ required: true, message: '请选择所属项目', trigger: 'change' }],
    resourceList: [{ required: true, message: '请上传文件' }]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getData()
    this.getTypeList()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.projectFile.selectProjectFilesByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then(res => {
      if (res) {
        this.total = res.total || 0
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  getTypeList () {
    this.$axios.get(this.$apis.projectFile.selectProjectFileTypesByList).then(res => {
      this.typeList = res.list || []
    })
  }

  onDelete (fileId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.projectFile.deleteProjectFiles, { fileId })
          .then(() => {
            this.$message.success('删除成功')
            this.searchData()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onType () {
    this.isShowType = true
  }

  // 下载
  onDownload (row: TableData) {
    window.location.href = `${row.resourceList[0].filePrefix + row.resourceList[0].fileUrl}?attname=${row.resourceList[0].fileName}`
  }

  onAdd () {
    this.addShow = true
    this.getTypeList()
  }

  searchData () {
    this.page = 1
    this.getData()
  }

  onSuccess (file: FileInfo) {
    this.info.resourceList = [file]
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.projectFile.insertProjectFiles, this.info).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
          this.searchData()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  closeDialog () {
    this.info = {
      fileTypeId: '',
      projectId: '',
      comments: '',
      resourceList: []
    };
    (this.$refs.info as ElForm).resetFields()
    this.addShow = false
  }
}
